*{
  font-family: Arial, Helvetica, sans-serif !important;
}

.register-container {
  color: white;
  width: 80%;
}

.register-container button[type="submit"] {
  width: 50%;
  margin: 0 auto;
}

.register-container-form div{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 10vh;
  padding: 8px;
  font-weight: bolder;
}

.register-container-form div label{
  font-size: 24px;
}

.register-container-form div input{
  font-size: 16px;
}

input[aria-label]{
  color: white !important;
  font-weight: bolder;
}


#order-button{
  background: white;
  color: black;    
}
#order-button b , #order-button svg{
  height: 16px;  
}


#order-button:hover{
  background-color: #24FF00;
  font-weight: bold;
}


#dashboard{
  display: flex;
  flex-direction: column;  
  align-items: center;
  max-height: 85vh;
  width: 100%;
}

#dashboard h1 {
  color: white;
}



#app{
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  min-height: 60vh;
  
}



.loading{
  color: white;
  text-align: center;  
}

body,.buttonSubmit{
  background-color: black;
}




#login button {
  border:none;
}

.header {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.header .menu {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 40rem;
  
}

.header .menu button {
  cursor: pointer;
  border-radius: 50px;
  width: 23%;
  border: 1px solid white;
  font-size: 20px;
  font-weight: bold;
  background: black;
  color: white;
  min-height: 4vh;
}
.header .menu button:hover{
  background-color: #fff;
  color: #000;
  transition: .3s ease;
}
.submit.hidden{
  display: none !important;
}

.submit,.submit svg{
  width: 100% !important;
  cursor: pointer;
}


.form.hidden {
  display: none;
}

.hidden{
  display: none !important;
}

.form form{
  display: flex;
  flex-direction: column;    
  padding: 8px;
}
.form form input,.form form label,.form form button {
  border-radius: 0px !important;
  margin: 8px;
}

#forms{
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 50%;      
}

#forms #loginForm {
  width: 100%;
  height: 50vh;
  margin: 20% auto;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

}

#loginPassword, #loginUsername {
  height: 13vh;
  border: 0;
  border-bottom: 1px solid white;
  background: black;
  color: white !important;
  font-size: 36px;
  text-align: center;
}

.modal-content{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;  
  max-height: 85vh;
  flex-wrap: nowrap;
  align-self: center;
  overflow-y: auto;
}

.strain-name {
  font-size: 32px;
  width: 80%;
  border: 1px solid black;
  border-radius: 50px;
  padding: 8px;
}

.genders,.images,.pdfs{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 80%;
    
}



.genders div {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 45%;
}

.genders div p {
  
    border: 1px solid black;
    width: 80%;
    height: 40px;
    border-radius: 50px;
    align-content: center;
}


.genders svg {
  max-height: 40px;
}


.modal-content {
  padding: 20px;
}

.info {
  
    display: flex;
    flex-direction: row;
    gap: 10px;
    margin-bottom: 20px;
    width: 80%;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

}

.info-item {
  padding: 10px;
  border: 1px solid black;
  border-radius: 5px;
  font-size: 14px;
  color: #000;
  min-width: 40%;
  
}

.info-item strong {
  color: #000;
  font-weight: bolder; /* Color más oscuro para las etiquetas */
}

.images {
  display: flex;
  justify-content: space-around; /* Espacio entre imágenes */
  margin-bottom: 20px; /* Espacio antes del PDF */
}

.images img {
  max-width: 21vw; /* Ajusta el tamaño de las imágenes */
  border-radius: 5px;
}

.pdfs {
  display: flex; /* Fondo suave para la sección de PDFs */
  flex-direction: column;
}

.pdfs svg {
  width: 60%;
}
.sign{
  font-size: 20px;
}


.purchases-container {
  padding: 20px;
  background-color: #000; /* Fondo negro para el contenedor */
  color: #FFF; /* Texto en blanco */
  min-height: 100vh; /* Asegúrate de que el contenedor tenga suficiente altura */
  
}

.purchases-title {
  margin-bottom: 20px; /* Espaciado inferior */
  font-size: 2rem; /* Tamaño de fuente del título */
  text-align: center; /* Centrar el título */
}

.purchase-item {
  background-color: #1c1c1c; /* Fondo más oscuro para los items */
  margin: 10px 0; /* Margen superior e inferior */
  border-radius: 8px; /* Bordes redondeados */
  padding: 15px; /* Espaciado interno */
}

.purchase-item p {
  line-height: 1.5; /* Espaciado entre líneas */
  font-size: 0.875rem; /* Tamaño de fuente más pequeño para los detalles */
  color: #fff;
}

.loading-message {
  color: #FFF; /* Mensaje de carga en blanco */
  text-align: center; /* Centrar el mensaje */
}

.error-message {
  color: red; /* Mensaje de error en rojo */
  text-align: center; /* Centrar el mensaje */
}



tr.MuiTableRow-root:hover  {
  background-color: #24FF00 !important;  
  font-weight: bolder !important;  
}

thead.MuiTableHead-root:hover *{
  
  color: #000 !important;
  font-weight: bolder !important;  
}


.MuiList-root.MuiList-padding.MuiList-dense {
  background-color: #FFF !important;
}


.css-14icotw-MuiTableContainer-root{
  max-height: 500px !important;
}

.matches-section,.matches-section table {
  width: 100%;
}

.matches-section table, .matches-section tr, .matches-section td{
  border: 1px solid black;
  font-size: 14px;
}

.add-to-order input{
  background: #D8E4D6;
  border: 1px solid black;
  border-radius: 16px;
  text-align: center;
}

.ReactModal__Content button{
  color: #000;
  font-weight: bolder;
}

.matches-section h3,.pdfs h3{
  font-size: 16px;
}


.batch-id-rectangle {
  position: relative;
  background-color: #000; /* Fondo negro */
  color: white; /* Texto en blanco */
  font-weight: bolder; /* Negrita más fuerte */
  bottom: 11vh; /* Espaciado de 11vh desde la parte inferior */
  min-height: 6vh; /* Altura mínima de 6vh */
  line-height: 4; /* Altura de línea de 4 */
  text-align: center; /* Centrar el texto */
}


.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPopover-paper.MuiMenu-paper {
  width: 30% !important;
  position: relative !important;
  left: 69% !important;
}

.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded:not(.table-purchases) {
  width: 100vw;
}

.ReactModal__Overlay.ReactModal__Overlay--after-open{
  background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(255,255,255,0.5102415966386555) 100%) !important;
  z-index: 2;
}

.users-table{
  width: 100%;
  text-align: center;
  border: 1px solid white
}

.users-table td {
  border: 1px solid white;
}






@media screen and (min-width:1368px){
  .genders,.images,.pdfs,.info,.matches-section{
   
  }
  .info-item{    
    align-content: center;
  }
  .info{
    width: 95%;
  }
  .genders svg {
    height: 64px !important;
    max-height: none !important;
  }
  .genders  p{
    height: 64px !important;
    max-height: none !important;
  }

}
  
  
@media screen and (max-width:1366px){
  .genders,.images,.pdfs,.info,.matches-section{
    height: auto !important;
  }

  .pdfs svg{
    height: 40px;
  }
}
